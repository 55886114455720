<template>
	<div class="box text-center size-px-24 pa-50">
		준비중입니다
	</div>
</template>

<script>
export default {
	name: 'Qna'
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: '1:1문의'
				, title: true
				, bottom: true
				, top_line: true
			}
		}
	}

	, created() {
		this.$bus.$emit('onLoad', this.program)
	}
}
</script>